<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-right" key="bc"></p-toast>
<div class="page-wrapper">
  <div class="authentication-box">
    <div class="container px-0 px-lg-5">
      <div class="row d-flex justify-content-center mx-0 mx-lg-5">
        <div class="col-12 col-lg-7 bg-white px-2 px-lg-3 justify-content-center shadow rounded-4 py-5">
          <div class="col-12 d-flex justify-content-center pb-3">
            <img ngSrc="../../../../assets/images/Logo3.png" width="100" height="70" priority="true" alt="Logo" />
          </div>
          <div class="col-12">
            <p class="text-muted mt-3">
              Por favor, introduce la dirección de correo electrónico asociada a tu cuenta. <br>
              Recibirás un mensaje con un enlace que te
              permitirá crear una nueva contraseña de manera segura.
            </p>
          </div>
          <div class="col-12">
            <form [formGroup]="authForm" (ngSubmit)="forgotPassword()" novalidate="">
              <div class="p-field p-col-6 p-md-6 bloque mt-1 mb-2">
                <label for="inputtext">Correo electrónico</label>
                <span class="p-float-label">
                  <input [formControlName]="'email'" class="w-100" type="text" id="inputtext" pInputText
                    autocomplete="off" [ngClass]="{
                      'ng-invalid ng-dirty':
                        error &&
                        (authForm.controls['email'].hasError('pattern') ||
                          authForm.controls['email'].hasError('required'))
                    }" />
                </span>
              </div>
              <div class="w-100 d-flex justify-content-end">
                <button [disabled]="submitted" pButton pRipple type="submit" class="p-button-raised mt-3 text-right">
                  @if(!submitted){<span>Enviar</span>} @if(submitted){
                  <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>}
                </button>
              </div>
            </form>
            <div class="text-center mt-4">
              <a class="text-danger pe-auto" style="cursor: pointer" routerLink="/auth/login">Iniciar sesión</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>