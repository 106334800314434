<div class="main-header-left d-none d-lg-block">
  <div class="logo-wrapper px-3 my-4 ">
    <a [routerLink]="'/dashboard'" class="mx-auto px-auto">
      <img class="lazyloaded" ngSrc="assets/images/Logo3.png" width="90" height="80" priority="true" />
    </a>
  </div>
</div>
<div class="sidebar custom-scrollbar shadow-sm mt-2">
  <ul class="sidebar-menu">
    @for (menuItem of menuItems; track $index) {
    <li [ngClass]="{ active: menuItem.active }">
      <!-- Sub -->
      @if (menuItem.type === 'sub') {
      <a href="javascript:void(0)" class="sidebar-header position-relative" (click)="toggletNavActive(menuItem)">
        <i class="{{ menuItem.class }}"></i>
        <span>{{ menuItem.title }}
          @if (menuItem.badgeType) {
          <span class="badge badge-{{ menuItem.badgeType }} ml-3">{{
            menuItem.badgeValue
            }}</span>
          }
        </span>
        @if (menuItem.children) {
        <i class="fa-solid fa-chevron-right position-absolute" style="right: 0px"></i>
        }
      </a>
      }
      <!-- Link -->
      @if (menuItem.type === 'link') {
      <a [routerLink]="!menuItem.type ? null : [menuItem.path]" (click)="toggleSidebar()" routerLinkActive="active"
        class="sidebar-header">
        <i class="{{ menuItem.class }}"></i>
        <span>{{ menuItem.title }}
          @if (menuItem.badgeType) {
          <span class="badge badge-{{ menuItem.badgeType }} ml-3">{{
            menuItem.badgeValue
            }}</span>
          }
        </span>
        @if (menuItem.children) {
        <i class="fa fa-angle-right pull-right"></i>
        }
      </a>
      }
      <!-- External Link -->
      @if (menuItem.type === 'extLink') {
      <a href="{{ !menuItem.type ? null : menuItem.path }}" class="sidebar-header">
        <span>{{ menuItem.title }}
          @if (menuItem.badgeType) {
          <span class="badge badge-{{ menuItem.badgeType }} ml-3">{{
            menuItem.badgeValue
            }}</span>
          }
        </span>
        @if (menuItem.children) {
        <i class="fa fa-angle-right pull-right"></i>
        }
      </a>
      }

      <!-- External Tab Link -->
      @if (menuItem.type === 'extTabLink') {
      <a href="{{ !menuItem.type ? null : menuItem.path }}" target="_blank" class="sidebar-header">
        <span>{{ menuItem.title }}
          @if (menuItem.badgeType) {
          <span class="badge badge-{{ menuItem.badgeType }} ml-3">{{
            menuItem.badgeValue
            }}</span>
          }
        </span>
        @if (menuItem.children) {
        <i class="fa fa-angle-right pull-right"></i>
        }
      </a>
      }
      <!-- 2nd Level Menu -->
      @if (menuItem.children) {
      <ul class="sidebar-submenu" [ngClass]="{
          'menu-open': menuItem.active,
          'menu-close': !menuItem.active
        }">
        @for (childrenItem of menuItem.children; track $index) {
        <li [ngClass]="{ active: childrenItem.active }">
          <!-- Sub -->
          @if (childrenItem.type === 'sub') {
          <a href="javascript:void(0)" (click)="toggletNavActive(childrenItem)">
            <i class="fa fa-circle"></i>
            <span>
              {{ childrenItem.title }}
              @if (childrenItem.badgeType) {
              <span class="badge badge-{{ childrenItem.badgeType }} pull-right">
                {{ childrenItem.badgeValue }}</span>
              }
            </span>
            @if (childrenItem.children) {
            <i class="fa fa-angle-down pull-right"></i>
            }
          </a>
          }

          <!-- Link -->
          @if (childrenItem.type === 'link') {
          <a [routerLink]="!childrenItem.type ? null : [childrenItem.path]" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <i class="fa fa-circle"></i><span>{{ childrenItem.title }}
              @if (childrenItem.badgeType) {
              <span class="badge badge-{{ childrenItem.badgeType }} pull-right">{{ childrenItem.badgeValue }}</span>
              }
            </span>
            @if (childrenItem.children) {
            <i class="fa fa-angle-down pull-right"></i>
            }
          </a>
          }

          <!-- External Link -->
          @if (childrenItem.type === 'extLink') {
          <a href="{{ !childrenItem.type ? null : childrenItem.path }}" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <i class="fa fa-circle"></i><span>{{ childrenItem.title }}
              @if (childrenItem.badgeType) {
              <span class="badge badge-{{ childrenItem.badgeType }} pull-right">{{ childrenItem.badgeValue }}
              </span>
              }
            </span>
            @if (childrenItem.children) {
            <i class="fa fa-angle-down pull-right"></i>
            }
          </a>
          }
          <!-- External Tab Link -->
          @if (childrenItem.type === 'extTabLink') {
          <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank">
            <i class="fa fa-circle"></i><span>{{ childrenItem.title }}
              @if (childrenItem.badgeType) {
              <span class="badge badge-{{ childrenItem.badgeType }} pull-right">{{ childrenItem.badgeValue }}</span>
              }
            </span>
            @if (childrenItem.children) {
            <i class="fa fa-angle-down pull-right"></i>
            }
          </a>
          }

          <!-- 3rd Level Menu -->
          @if (childrenItem.children) {
          <ul class="sidebar-submenu">
            @for (childrenSubItem of childrenItem.children; track $index) {
            <li>
              <!-- Link -->
              @if (childrenSubItem.type === 'link') {
              <a [routerLink]="
                  !childrenSubItem.type ? null : [childrenSubItem.path]
                " routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <i class="fa fa-circle"></i><span>
                  {{ childrenSubItem.title }}
                  @if (childrenSubItem.badgeType) {
                  <span class="badge badge-{{
                      childrenSubItem.badgeType
                    }} pull-right">{{ childrenSubItem.badgeValue }}</span>
                  }
                </span>
              </a>
              }

              <!-- External Link -->
              @if (childrenSubItem.type === 'extLink') {
              <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <i class="fa fa-circle"></i><span>{{ childrenSubItem.title }}
                  @if (childrenSubItem.badgeType) {
                  <span class="badge badge-{{
                      childrenSubItem.badgeType
                    }} pull-right">{{ childrenSubItem.badgeValue }}</span>
                  }
                </span>
              </a>
              }

              <!-- External Tab Link -->
              @if (childrenSubItem.type === 'extTabLink') {
              <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" target="_blank">
                <i class="fa fa-circle"></i><span>{{ childrenSubItem.title }}
                  @if (childrenSubItem.badgeType) {
                  <span class="badge badge-{{
                      childrenSubItem.badgeType
                    }} pull-right">{{ childrenSubItem.badgeValue }}</span>
                  }
                </span>
              </a>
              }
            </li>
            }
          </ul>
          }
        </li>
        }
      </ul>
      }
    </li>
    }
    <li>
      <span href="javascript:void(0)" style="cursor: pointer;" class="cursor-pointer sidebar-header position-relative"
        (click)="logout()">
        <i class="fa fa-user"></i>
        <span>Cerrar sesión</span>
      </span>
    </li>
  </ul>
</div>