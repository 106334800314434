import { Injectable, HostListener, Inject, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	use?: string;
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	class?: string
}

@Injectable({
	providedIn: 'root'
})


export class NavService implements OnInit {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(
	) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}


	ngOnInit() {
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?: any) {
		this.screenWidth = window.innerWidth;
	}
	MENUITEMS: Menu[] = [
		{
			use: 'admin, contador',
			title: 'Dashboard',
			path: '/dashboard',
			class: 'fa-solid fa-chart-line',
			type: 'link',
			badgeType: 'primary',
			active: false
		},
		{
			use: 'admin',
			title: 'Compras',
			class: "fa-solid fa-list-check",
			icon: 'gift', type: 'sub', active: false, children: [
				{ path: '/purchases/all', title: 'Lista de compras', type: 'link' },
				{ path: '/products', title: 'Productos', type: 'link' },
				{ path: '/list/suppliers', title: 'Proveedores', type: 'link' },
			]
		},
		{
			use: 'admin',
			title: 'Ventas',
			class: "fa-solid fa-list-check",
			icon: 'gift', type: 'sub', active: false, children: [
				{ path: '/list/clients', title: 'Clientes', type: 'link' },
				{ path: '/sales/all', title: 'Ventas', type: 'link' },
				{ path: '/sale-point', title: 'Punto de venta', type: 'link' },
			]
		},
		{
			use: 'admin',
			title: 'Reportes',
			class: "fa-solid fa-list-check",
			icon: 'gift', type: 'sub', active: false, children: [
				{ path: '/sales/report', title: 'Caja', type: 'link' },
				{ path: '/branches/list', title: 'Sucursales', type: 'link' },
				{ path: '/sales/report', title: 'Inventario', type: 'link' },
			]
		},
		/* {
			use: 'admin, contador',
			title: 'Ventas Generadas',
			class: "fa fa-shopping-cart",
			icon: 'gift',
			active: false,
			path: '/sales/generated',
			type: 'link'
		}, */
		{
			use: 'admin',
			title: 'Usuarios',
			class: "fa-solid fa-users",
			path: '/users/list/admins',
			type: 'link'
		},
		/* {
			use: 'all',
			title: 'Punto de venta',
			class: "fa-solid fa-users",
			path: '/sale-point',
			type: 'link'
		}, */
		{
			use: 'admin',
			title: 'Catálogos',
			class: "fa-solid fa-list-check",
			icon: 'gift', type: 'sub', active: false, children: [
				{ path: '/catalogs/list/category', title: 'Categoría de producto', type: 'link' },
				{ path: '/catalogs/documents', title: 'Documentos', type: 'link' },
				{ path: '/catalogs/list/families', title: 'Familia de producto', type: 'link' },
				{ path: '/catalogs/list/paymentsway', title: 'Formas de pago', type: 'link' },
				{ path: '/catalogs/taxes', title: 'Impuestos', type: 'link' },
				{ path: '/catalogs/list/paymentsmethod', title: 'Métodos de pago', type: 'link' },
				{ path: '/catalogs/list/taxregime', title: 'Regimen Fiscal', type: 'link' },
				{ path: '/catalogs/list/subcategory', title: 'Subcategoría de producto', type: 'link' },
				{ path: '/catalogs/list/unitmeasure', title: 'Unidades de medida', type: 'link' },
				{ path: '/catalogs/list/cfdiusage', title: 'Uso de CFDI', type: 'link' },
			]
		},
		{
			use: 'admin',
			title: 'Configuración',
			class: "fa-solid fa-gears",
			icon: 'gift', type: 'sub', active: false, children: [
				{ path: '/settings/contact', title: 'Contacto', type: 'link' },
				{ path: '/settings/legal', title: 'Legal', type: 'link' },
				{ path: '/settings/visual', title: 'Visual', type: 'link' },
			]
		},
		{
			use: 'admin, contador, vendedor',
			title: 'Perfil',
			class: "fa fa-user",
			icon: 'gift',
			active: false,
			path: '/profile',
			type: 'link'
		},
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
