<div id="notfound">
    <div class="notfound">
        <div class="notfound-404 mb-5">
            <h1>404</h1>
        </div>
        <h2 class="mt-4">¡Oops! No hemos encontrado la página que buscabas</h2>
        <p>Lo sentimos, pero la página que buscas no existe, ha sido movida, ha cambiado de nombre o no se encuentra
            disponible en este momento</p>
        <a href="/">Ir a la página de inicio</a>
    </div>
</div>